import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import "../../styles/odooServices.scss"
import News from "../../components/News"
import OdooTopSection from "../../components/OdooTopSection"
import WhyChooseVisiomateforD365 from "../../components/WhyChooseVisiomateforD365"
import Dynamics365OptimizationImageWithTextSection from "../../components/Dynamics365OptimizationImageWithTextSection"
import OdooIntegrationSimplified from "../../components/OdooIntegrationSimplified"
import Wethoughtyoumighthavequestions from "../../components/Wethoughtyoumighthavequestions"
import RecentSuccessStories from "../../components/RecentSuccessStories"

import img1 from "../../images/DemoImages/jaredd-craig-HH4WBGNyltc-unsplash.jpg"
import img2 from "../../images/DemoImages/nathan-dumlao-LPRrEJU2GbQ-unsplash.jpg"
const Index = ({ data }) => {
    return (
        <div className="Integration">
            <Layout pageTitle={"Visiomate - Integration with Odoo"}
            descrip = {"Integrate your critical tools with Odoo to enhance productivity, automate workflows, and gain a 360-degree view of your business. Explore the possibilities today!"}>
                <OdooTopSection
                    headerData={{
                        title:
                            "Unlock the potential of your business with our expertise in seamlessly integrating Odoo with other applications, ensuring a smooth and powerful collaboration.",
                    }}
                    data={data}
                />
                <Dynamics365OptimizationImageWithTextSection
                    content={{
                        heading:
                            "Empowering the Core of Your Business by Seamlessly Integrating All Components Together",
                        description:
                            "Integration middleware enhances Odoo by seamlessly connecting data, ensuring real-time visibility, and optimizing efficiency. With automated processes, cost-effective operations, and heightened security, it streamlines Odoo, fostering agility and scalability.",
                        d2:
                            "This middleware expedites implementation, ensures compliance, and offers centralized management for a responsive and competitive ecosystem.",
                    }}
                    data={data}
                />
                <WhyChooseVisiomateforD365
                    data={{
                        title: "Why Choose Visiomate for Odoo Integration?",
                        headings: [
                            {
                                heading: "Platform Understanding",
                                description:
                                    "Our integration experts have a deep understanding of Odoo's platform, including its data structure, APIs, and workflows. This knowledge is vital for crafting integrations that align seamlessly with Odoo's architecture, ensuring optimal performance.",
                            },
                            {
                                heading: "Experience with Diverse Systems",
                                description:
                                    "Experienced teams have integrated Odoo with diverse third-party applications, databases, and platforms. This broad experience equips them to adeptly handle various integration scenarios and challenges.",
                            },
                            {
                                heading: "Efficient Data Mapping",
                                description:
                                    "Accurate data mapping is crucial for successful integration. Our expert teams excel in precisely mapping data fields, ensuring seamless consistency and reliability between Odoo and other systems. ",
                            },
                            {
                                heading: "Error Handling and Troubleshooting",
                                description:
                                    "Integrations may encounter challenges, and our experts are adept at error handling and troubleshooting. Their swift identification and resolution of issues minimize downtime, ensuring a reliable integration process.",
                            },
                        ],
                    }}
                    dataFormImages={data}
                />
                <RecentSuccessStories
                    data={{
                        title: "Recent Success Stories ",
                        Text: [
                            {
                                description:
                                    "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. ",
                                image: img1,
                            },
                            {
                                description:
                                    "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. ",
                                image: img2,
                            },
                        ],
                    }}
                    dataFormImages={data}
                />
                <OdooIntegrationSimplified />
                <Wethoughtyoumighthavequestions
                    data={[
                        {
                            question: "What is integration with Odoo?",
                            answer:
                                "Integration with Odoo refers to the process of connecting Odoo, an open-source suite of business applications, with other systems, applications, or services to facilitate data exchange, automate workflows, and streamline business processes. Integration allows organizations to leverage the capabilities of Odoo alongside other tools or platforms they use in their operations.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "Why should I consider integrating Odoo with other systems?",
                            answer:
                                "Integrating Odoo with other systems offers several benefits, including:",
                            answer1:
                                "- Seamless Data Flow: Integration ensures a seamless flow of data between Odoo and other systems, eliminating manual data entry and reducing errors.",
                            answer2:
                                "- Process Automation: Integrating workflows across systems enables automation of repetitive tasks, improving efficiency and productivity.",
                            answer3:
                                "- Enhanced Insights: By consolidating data from multiple sources, integration provides a holistic view of business operations, enabling better decision-making.",
                            answer4:
                                "- Improved Customer Experience: Integration allows for a unified customer experience across different touchpoints, leading to increased customer satisfaction and loyalty.",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question: "What systems can be integrated with Odoo?",
                            answer:
                                "Odoo can be integrated with a wide range of systems, including:",
                            answer1:
                                "- E-commerce Platforms: Shopify, WooCommerce, Magento, etc.",
                            answer2: "- Accounting Software: QuickBooks, Xero, Sage, etc.",
                            answer3: "- CRM Systems: Salesforce, HubSpot, Zoho CRM, etc.",
                            answer4:
                                "- Marketing Automation Tools: Mailchimp, Marketo, Pardot, etc.",
                            answer5: "- Payment Gateways: PayPal, Stripe, Square, etc.",
                            answer6:
                                "- Shipping and Logistics Providers: FedEx, UPS, DHL, etc.",
                            answer7:
                                "- Inventory Management Systems: Fishbowl, TradeGecko, Cin7, etc.",
                            answer8: "- HR and Payroll Systems: BambooHR, Gusto, ADP, etc.",
                            answer9:
                                "- Custom Applications: In-house developed software or legacy systems.",
                        },
                        {
                            question:
                                "What are the common use cases for integration with Odoo?",
                            answer: "Common use cases for integration with Odoo include:",
                            answer1:
                                "- Order Management: Integrating e-commerce platforms or POS systems with Odoo for seamless order processing and inventory management.",
                            answer2:
                                "- Accounting Integration: Syncing financial data between Odoo and accounting software for accurate reporting and reconciliation.",
                            answer3:
                                "- CRM Integration: Connecting CRM systems with Odoo to streamline lead management, customer interactions, and sales processes.",
                            answer4:
                                "- Marketing Automation: Integrating marketing tools with Odoo for targeted campaigns, lead nurturing, and customer engagement.",
                            answer5:
                                "- Payment Gateway Integration: Enabling secure payment processing by integrating payment gateways with Odoo for online transactions.",
                            answer6:
                                "- Shipping and Logistics Integration: Automating shipping workflows by integrating Odoo with shipping carriers for real-time tracking and delivery updates.",
                            answer7:
                                "- HR and Payroll Integration: Syncing employee data between HR systems and Odoo for payroll processing, attendance tracking, and workforce management.",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question: "How can I integrate Odoo with other systems?",
                            answer:
                                "Integration with Odoo can be achieved through various methods, including:",
                            answer1:
                                "- APIs (Application Programming Interfaces): Odoo provides RESTful APIs and XML-RPC interfaces for seamless integration with external systems.",
                            answer2:
                                "- Webhooks: Using webhooks to trigger events and communicate data between Odoo and other systems in real-time.",
                            answer3:
                                "- Middleware: Implementing middleware platforms or integration tools like Zapier, Integromat, or Middleware solutions to orchestrate data flow and automate integration processes.",
                            answer4:
                                "- Custom Development: Developing custom integrations using Python programming language and Odoo's development framework to tailor integration solutions to specific business requirements.",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "What are the key considerations for successful integration with Odoo?",
                            answer:
                                "Key considerations for successful integration with Odoo include:",
                            answer1:
                                "- Understanding Business Requirements: Clearly defining integration objectives, use cases, and requirements to ensure alignment with business goals.",
                            answer2:
                                "- Data Mapping and Transformation: Mapping data fields between systems and defining data transformation rules to ensure data consistency and accuracy.",
                            answer3:
                                "- Security and Compliance: Implementing robust security measures, data encryption, and access controls to protect sensitive information and ensure compliance with regulatory requirements.",
                            answer4:
                                "- Scalability and Performance: Designing integration solutions that can handle increasing data volumes and scale with business growth while maintaining optimal performance.",
                            answer5:
                                "- Monitoring and Maintenance: Establishing monitoring mechanisms and proactive maintenance routines to identify and address integration issues, errors, or performance bottlenecks.",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "Where can I get assistance with integration projects involving Odoo?",
                            answer:
                                "Organizations can seek assistance with integration projects involving Odoo from certified Odoo partners, consultants, and solution providers. These professionals offer expertise in Odoo integration, customization, development, and support services tailored to the specific needs of each organization.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                    ]}
                    title="We thought you might have questions"
                />
                <News isAbout={true} career={true} />
            </Layout>
        </div>
    )
}

export default Index
export const query = graphql`
  query OdoooIntegrationImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odooimg: file(relativePath: { eq: "Odoobg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages1: file(
      relativePath: { eq: "DemoImages/jaredd-craig-HH4WBGNyltc-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages2: file(
      relativePath: {
        eq: "DemoImages/kourosh-qaffari-RrhhzitYizg-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SecondDivImg: file(
      relativePath: { eq: "flat-lay-brushes-isolated-white-background 1.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
