import React, { memo } from "react"
import Img from "gatsby-image"
import IconsAdded from "./IconsAdded"
const RecentSuccessStories = ({ data, dataFormImages }) => {
    return (
        <div className="position-relative">
        <div className="RecentSuccessStories">
            <h1 className="heading">{data.title}</h1>
            <div class="second-container">
                {data.Text.map((d, index) => {
                    return (
                        <div className={`div${index + 1}`} key={index}>
                            <div>
                                <Img
                                    fluid={
                                        dataFormImages[`RSSImages${index + 1}`].childImageSharp
                                            .fluid
                                    }
                                />
                            </div>
                            <p>{d.description}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        <IconsAdded iconColor={"yellow"}/>
        </div>
    )
}

export default memo(RecentSuccessStories)
